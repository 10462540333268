import React, { useEffect, useRef } from "react"
import Navigation from "../../layout/navigation/Navigation"
import { useHasBeenViewed } from "../../hooks/useHasBeenViewed"
import smoothscroll from "smoothscroll-polyfill"
import { Link } from "react-router-dom"

import "./landing.sass"
import "../../assets/sass/_animations.sass"

import clsx from "clsx"
import { ArrowRight } from "react-feather"

smoothscroll.polyfill()

export default function Landing() {
  useEffect(() => {
    document.title = "Prospiro | Supercharge Your Reputation and Customer Base"
  }, [])

  const howItWorksSection = useRef(null)

  const [isStep1Visible, step1] = useHasBeenViewed({ threshold: 35 })
  const [isStep2Visible, step2] = useHasBeenViewed({ threshold: 35 })
  const [isStep3Visible, step3] = useHasBeenViewed({ threshold: 35 })
  const [isStep4Visible, step4] = useHasBeenViewed({ threshold: 35 })

  return (
    <main>
      <Navigation activePage="home" collapse />
      <section
        className="section is-hero hero landing-hero is-fullheight has-custom-font"
        role="contentinfo"
        aria-labelledby="sectionHeading"
        name="Landing Hero"
      >
        {/* <div className="hero-bg fade-in-slow" /> */}
        <div className="hero-body center">
          <div className="container">
            <div className="columns">
              <div className="column is-flex is-flex-direction-column is-justify-content-center">
                <h1 className="title is-spaced pb-3 slide-in-bottom has-cascade-effect mb-0">
                Supercharge Your Reputation and Customer Base
                </h1>
                <h3
                  className=" slide-in-bottom has-cascade-effect has-text-grey landing-subtitle"
                  style={{ maxWidth: 420 }}
                >
                  {/* Unlock the power of automated review collection to boost customer engagement and attract more clients. */}
                  Unlock the power of automated review collection to elevate your business's online presence and attract more customers.

                </h3>
                <div className="buttons mt-5 fade-in-fast slide-in-bottom has-cascade-effect">
                  <Link
                    to={{ pathname: "https://www.jotform.com/240434160924248"}}
                    target="_blank"
                    className="button is-size-5 is-primary is-rounded-large has-text-weight-semibold cta-button has-custom-font"
                  >
                    Get Started
                  </Link>

                  <button
                    className="button is-white is-rounded-large has-text-grey-dark has-text-weight-semibold has-custom-font is-size-6 is-text ml-3"
                    onClick={() => {
                      if (howItWorksSection.current) {
                        const y =
                          howItWorksSection.current.getBoundingClientRect()
                            .top + window.pageYOffset

                        window.scrollTo({ top: y, behavior: "smooth" })
                      }
                    }}
                  >
                    Learn more
                  </button>
                </div>
              </div>
              <div className="column is-half">
                <img
                  src={require("../../assets/images/landing/hero-img.png")}
                  alt=""
                  className="fade-in slide-in-bottom"
                  loading="lazy"
                  draggable={false}
                  width="auto"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr className="container" />
      <section className="section pt-5 has-custom-font" ref={howItWorksSection}>
        <h2 className=" has-text-centered is-size-3 has-text-weight-semibold mb-6 mt-6">
        How It Works
        </h2>

        {/* PROFILE */}
        <div
          className={clsx(
            "columns container is-fluid is-7 is-variable is-centered mb-5",
            {
              "is-invisible": !isStep1Visible,
              "slide-in-bottom": isStep1Visible
            }
          )}
          ref={step1}
        >
          <div className="column is-6 has-text-centered">
            <img
              src={require("../../assets/images/landing/review-collection.png")}
              alt="Lead Capture"
              className="landing-product-img fade-in"
              loading="lazy"
              draggable={false}
            />
          </div>
          <div className="column is-flex is-justify-content-center is-flex-direction-column">
            <div className="landing-product-text">
              <h3 className="has-text-weight-semibold is-size-5 ">
              Automated Review Collection
              </h3>
              <p className="mt-3">
              As soon as an appointment concludes, our system automatically sends a personalized text message to your client, ensuring prompt feedback collection and boosting engagement.
              </p>
            </div>
          </div>
        </div>

        {/* JOBS */}
        <div
          className={clsx(
            "columns is-vcentered container is-fluid is-7 is-variable is-centered pt-6 is-reversed mb-5",
            {
              "is-invisible": !isStep2Visible,
              "slide-in-bottom": isStep2Visible
            }
          )}
          ref={step2}
        >
          <div className="column is-flex is-justify-content-center is-flex-direction-column is-align-items-flex-end">
            <div className="landing-product-text">
              <h3 className="has-text-weight-semibold is-size-5">
              Publicize Positive Reviews
              </h3>
              <p className="mt-3">
              Positive feedback from happy customers is published on a major platform like Google, Yelp, and Facebook, improving your review score and amplifying your business's visibility and trustworthiness.
              </p>
            </div>
          </div>
          <div className="column is-6 has-text-centered">
            <img
              src={require("../../assets/images/landing/positive-review.png")}
              alt="Scheduled Outreach"
              className="landing-product-img fade-in"
              loading="lazy"
              draggable={false}
            />
          </div>
        </div>

        {/* EXTENSION */}
        <div
          className={clsx(
            "columns container is-fluid is-7 is-variable is-centered pt-6 mb-5",
            {
              "is-invisible": !isStep3Visible,
              "slide-in-bottom": isStep3Visible
            }
          )}
          ref={step3}
        >
          <div className="column is-6 has-text-centered">
            <img
              src={require("../../assets/images/landing/negative-review.png")}
              alt="Multichannel Follow-ups"
              className="landing-product-img fade-in"
              loading="lazy"
              draggable={false}
            />
          </div>
          <div className="column is-flex is-justify-content-center is-flex-direction-column">
            <div className="landing-product-text">
              <h3 className="has-text-weight-semibold is-size-5">
              Handle Negative Feedback Privately
              </h3>
              <p className="mt-3">
              Negative feedback through our platform is delivered directly to your email instead of being published online, empowering you to address concerns privately while safeguarding your online reputation.
              </p>

            </div>
          </div>
        </div>

        {/* APPLICATIONS */}
        <div
          className={clsx(
            "columns is-vcentered container is-fluid is-7 is-variable is-centered pt-6 is-reversed mb-5",
            {
              "is-invisible": !isStep4Visible,
              "slide-in-bottom": isStep4Visible
            }
          )}
          ref={step4}
        >
          <div className="column is-flex is-justify-content-center is-flex-direction-column is-align-items-flex-end">
            <div className="landing-product-text">
              <h3 className="has-text-weight-semibold is-size-5 ">
              Grow Through Referrals
              </h3>
              <p className="mt-3">
              After leaving a positive review, satisfied customers are automatically re-engaged and incentivized to refer friends and family, expanding your customer base through positive word-of-mouth referrals.
              </p>
            </div>
          </div>
          <div className="column is-6 has-text-centered">
            <img
              src={require("../../assets/images/landing/referral.png")}
              alt="Effortless Automation"
              className="landing-product-img fade-in"
              loading="lazy"
              draggable={false}
            />
          </div>
        </div>
      </section>

      <hr className="container mt-5" />

      <section className="section pt-5 has-custom-font has-text-centered">
        <div className="columns is-centered">
        <div className="column is-8">
        <h2 className="is-size-3 has-text-weight-semibold mb-6 mt-6">
        Leverage Customer Experiences for Growth 💸
        </h2>
        <p className="is-size-5">Transform positive customer experiences into powerful marketing assets, attracting new clients and solidifying your reputation as a top-tier business.</p>
        </div>
        </div>
        </section>

        <hr className="container mt-5" />

      <section className="section has-custom-font has-text-centered">
        <h2 className="is-size-5 has-text-weight-semibold mb-2">
        Ready to Unlock Your Potential? ✅
        </h2>
        <p className="mb-2 m-auto has-text-grey" style={{ maxWidth: 520 }}>
        Empower your business by transforming satisfied customers into advocates.
        </p>
        <Link
          to={{ pathname: "https://www.jotform.com/240434160924248"}}
          target="_blank"
          className="button is-text has-text-weight-semibold has-custom-font mr-0 has-text-primary"
        >
          Get Started
          <ArrowRight className="ml-2" strokeWidth={2} />
        </Link>
      </section>


      <footer className="has-text-grey pb-5 mt-6">
        <div className="container is-flex is-justify-content-space-between is-align-items-center">
          <img
            src={require("../../assets/images/logo-dark.png")}
            alt="Ascenta Logo"
            width="110px"
            height="32px"
          />
          {/* <Link to="/privacy" className="has-text-dark has-text-">
            Privacy Policy
          </Link> */}
          {/*<p>© 2021</p>*/}
        </div>
      </footer>
    </main>
  )
}
