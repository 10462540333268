import React, { useState } from "react"
import { Link } from "react-router-dom"
import clsx from "clsx"
// import { MessageCircle } from "react-feather"
// import { ReactComponent as Home } from "../../assets/images/icons/home.svg"

import "./navigation.sass"
import "../../assets/sass/_animations.sass"
// import { Link } from "react-router-dom"

export default function Navigation({ activePage, fixed, collapse }) {
  const [toggleMenu, setToggleMenu] = useState(null)

  return (
    <nav
      className={clsx("navbar pt-2", {
        "is-fixed has-background-white": fixed
      })}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img
              src={require("../../assets/images/logo-colored.png")}
              alt="Prospiro Logo"
              width="110px"
              height="32px"
            />
          </Link>

          {collapse && (
            <button
              className={clsx("button py-1 is-text mt-1 navbar-burger burger", {
                "is-active": toggleMenu
              })}
              aria-label="menu"
              aria-expanded="false"
              onClick={(e) => {
                e.preventDefault()
                setToggleMenu(!toggleMenu)
              }}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </button>
          )}
        </div>

        <div
          onTransitionEnd={(e) =>
            e.currentTarget.classList.remove("has-navigation-transition")
          }
          className={clsx("navbar-menu", {
            "is-active has-navigation-transition":
              toggleMenu === true && collapse,
            "has-navigation-transition": toggleMenu === false && collapse,
            "has-collapse": collapse,
            "is-hidden": !collapse
          })}
        >
          <div className="navbar-start mt-2 ml-1">
            {/* <a
              className={clsx("navbar-item is-rounded", {
                "has-text-weight-bold": props.activePage === "about"
              })}
              href="/about"
            >
              About
            </a>
            <a className="navbar-item is-rounded" href="#features">
              Features
            </a> */}
            {/*<a
              className={clsx("navbar-item is-rounded", {
                "has-text-weight-bold": props.activePage === "blog"
              })}
              href="/about"
            >
              Blog
            </a>*/}
            {/* <a
              className={clsx("navbar-item is-rounded", {
                "has-text-weight-bold": props.activePage === "pricing"
              })}
              href="/pricing"
            >
              Pricing
            </a> */}
            {/* <a
              className={clsx("navbar-item is-rounded", {
                "has-text-weight-bold": props.activePage === "support"
              })}
              href="/support"
            >
              Support
            </a> */}
          </div>
          {/* <div className="navbar-end">
            <div className="navbar-item">

              <div className="buttons is-flex-centered">
                <Link
                  to="/contact"
                  className="button is-text has-text-weight-semibold has-custom-font mr-0"
                >
                  Contact
                </Link>
                <Link
                  to="/login"
                  className="button is-text has-text-weight-semibold has-custom-font"
                >
                  Log in
                </Link>
                <Link
                  to="/signup"
                  className="button has-text-primary has-text-weight-semibold has-custom-font"
                >
                  Sign up
                </Link>
              </div>
              {/* ) : ( */}
              {/* <Link
                  className="button is-white pl-5 pr-0 has-text-grey-dark has-background-white has-text-weight-semibold has-custom-font"
                  aria-label="Applyd Home"
                  to="/"
                >
                  Home
                  <Home
                    width={19}
                    height={19}
                    strokeWidth={2}
                    className="ml-3 "
                  />
                </Link> */}
              {/* )} */}
              {/* {props?.activePage === "home" ? (
                <Link
                  className="button is-white pl-4 pr-0"
                  style={{ background: "transparent" }}
                  aria-label="Contact us"
                  to="/contact"
                >
                  Get in touch{" "}
                  <MessageCircle
                    height={19}
                    width={19}
                    strokeWidth={2}
                    className="ml-2"
                  />
                </Link>
              ) : (
                <Link
                  className="button is-white pl-5 pr-0 has-background-white"
                  aria-label="Applyd Home"
                  to="/"
                >
                  Home
                  <Home
                    width={19}
                    height={19}
                    strokeWidth={2}
                    className="ml-3 "
                  />
                </Link>
              )} */}
            {/* </div>
          </div> */} 
        </div>
      </div>
    </nav>
  )
}
